import React from 'react';

import Layout from '../layout/index';

import SEO from '../components/seo/index';

import '../assets/styles/pages/faq.scss';

import aboutbg from '../assets/images/about-bg.svg';

import aboutAshram from '../assets/images/about/about-ashram.png';

import aboutbackBg from '../assets/images/about/about-bg.png';

// import { Carousel } from 'react-bootstrap';

const AboutUs = () => {
    return (
        <>
            <SEO title='AboutUs' />
            <Layout>
                <div >
                    <div className="abtimg">
                        <img
                            src={aboutbg}
                            alt="First slide"
                        />
                        <p className="text">About</p>
                    </div>
                </div>
                <div className="about-sec">
                    <div className='col-md-12'>
                        <section className='section padding-abt'>
                            <div className='container'>
                                <div className='col-md-12'>
                                    <div>
                                        <h3 className="header-tag">Gurgaon Seva Ashram</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='col-md-6'>
                                        <div className="about-text">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                            when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                            It has survived not only five centuries, but also the leap into electronic typesetting,
                                            remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                                            and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                            when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                            It has survived not only five centuries, but also the leap into electronic typesetting,
                                            remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset
                                            sheets containing Lorem Ipsum passages, and more recently with desktop publishing software
                                            like Aldus PageMaker including versions of Lorem Ipsum.
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div>
                                            <img src={aboutAshram} width='569px' height='369px' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

            </Layout>
        </>
    );
};

export default AboutUs;
